import React, { useEffect, useState } from "react";
import Avatar from "../profile.png";
import {
  onSnapshot,
  doc,
  deleteDoc,
  query,
  collection,
} from "firebase/firestore";
import { db } from "../firebase";
import Delete from "../components/svg/Delete";

const User = ({ user, selectUser, sender, chat }) => {
  const receiver = user?.uid;
  const [data, setData] = useState(null);

  useEffect(() => {
    const id = `${sender + receiver}`;
    let unsub = onSnapshot(doc(db, "lastMsg", id), (doc) => {
      setData(doc.data());
    });
    return () => unsub();
  }, [sender, receiver]);

  const deleteImage = async () => {
    try {
      const confirm = window.confirm("Delete conversation?");
      if (confirm) {
        // const id =
        //   sender > receiver ? `${sender + receiver}` : `${receiver + sender}`;
        const id = `${sender + receiver}`;

        const msgsRef = collection(db, "messages", id, "chat");

        onSnapshot(query(msgsRef), (querySnapshot) => {
          querySnapshot.forEach(async (document) => {
            const docRef = doc(db, `messages/${id}/chat`, document.id);
            await deleteDoc(docRef);
          });
        });
        // window.location = "/";
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getInitail=(data)=>{
    switch (data.type) {
      case "text":return data.text;
      case "audio":return "Sent a voice";
      default : return "Sent a file"; 
    }
  }

  return (
    <>
      <div
        className={`user_wrapper ${
          chat && chat.name === user.name && "selected_user"
        }`}
        onClick={() => selectUser(user)}
      >
        <div className="user_info">
          <div className="user_detail">
            <img src={user.avatar || Avatar} alt="avatar" className="avatar" />
            <h4>{user.name}</h4>
            {data?.from !== sender && data?.unread && (
              <small className="unread">New</small>
            )}
          </div>
          <Delete deleteImage={deleteImage} />
          {/* <div
            className={`user_status ${user.isOnline ? "online" : "offline"}`}
          ></div> */}
        </div>
        {data && (
          <p className="truncate">
            <strong>{data.from === sender ? "Me:" : null}</strong>
         
          </p>
        )}
      </div>
      <div
        onClick={() => selectUser(user)}
        className={`sm_container ${
          chat && chat.name === user.name && "selected_user"
        }`}
      >
        <img
          src={user.avatar || Avatar}
          alt="avatar"
          className="avatar sm_screen"
        />
      </div>
    </>
  );
};

export default User;
