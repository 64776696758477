import React, { useEffect, useRef, useState } from "react";
import { db, auth, storage } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import User from "../components/User";
import MessageForm from "../components/MessageForm";
import Message from "../components/Message";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState(null);
  const [text, setText] = useState("");
  const [selectedMsg, setSelectedMsg] = useState(null);

  const [msgs, setMsgs] = useState([]);

  const filePickerRef = useRef();

  const sender = auth.currentUser.uid;

  useEffect(() => {
    const usersRef = collection(db, "users");
    // create query object
    const q = query(usersRef, where("uid", "not-in", [sender]));
    // execute query
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      setUsers(users);
    });
    return () => unsub();
  }, [sender]);

  const selectUser = async (user) => {
    setChat(user);

    const receiver = user.uid;
    const id = `${sender + receiver}`;

    const msgsRef = collection(db, "messages", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));

    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push({ id: doc.id, ...doc.data() });
      });
      setMsgs(msgs);
    });

    const docSnap = await getDoc(doc(db, "lastMsg", id));
    if (docSnap.data() && docSnap.data().from !== sender) {
      await updateDoc(doc(db, "lastMsg", id), { unread: false });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const receiver = chat.uid;
    const id = `${sender + receiver}`;

    await addDoc(collection(db, "messages", id, "chat"), {
      type: "text",
      name: "text",
      text: text,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),
    });

    await setDoc(doc(db, "lastMsg", id), {
      type: "text",
      name: "text",
      text: text,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),

      unread: true,
    });
    setText("");
  };

  const handleUploadClick = () => {
    filePickerRef.current.click();
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    let fileName = e.target.files[0].name;
    let fileType = fileName.substr(fileName.lastIndexOf(".") + 1);

    if (["jpg", "png", "jpeg"].includes(fileType)) {
      fileType = "image";
      fileName = "image";
    }

    let formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      "http://hpapi.tbtheunion.webstarterz.com/api/chat/fileUpload",

      {
        method: "Post",
        body: formData,
        headers: {
          accept: "application/json",
        },
      }
    );
    let data = await response.json();
    const receiver = chat.uid;
    const id = `${sender + receiver}`;

    await addDoc(collection(db, "messages", id, "chat"), {
      type: fileType,
      name: fileName,
      text: data.file.file,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),
    });

    await setDoc(doc(db, "lastMsg", id), {
      type: fileType,
      name: fileName,
      text: data.file.file,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),

      unread: true,
    });
  };

  const handleAudioUpload=async (file)=>{
    let formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      "http://hpapi.tbtheunion.webstarterz.com/api/chat/fileUpload",

      {
        method: "Post",
        body: formData,
        headers: {
          accept: "application/json",
        },
      }
    );
    let data = await response.json();
    const receiver = chat.uid;
    const id = `${sender + receiver}`;

    await addDoc(collection(db, "messages", id, "chat"), {
      type: "audio",
      name: "voice",
      text: data.file.file,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),
    });

    await setDoc(doc(db, "lastMsg", id), {
      type: "audio",
      name: "voice",
      text: data.file.file,
      from: sender,
      to: receiver,
      createdAt: Timestamp.fromDate(new Date()),

      unread: true,
    });
  }

  const handleMessageDelete = async () => {
    setMsgs((prevArray) =>
      prevArray.filter((e, index) => index !== selectedMsg?.key)
    );

    const receiver = chat.uid;
    const id = `${sender + receiver}`;
    const docRef = doc(db, `messages/${id}/chat`, selectedMsg.msg.id);
    await deleteDoc(docRef);
    setSelectedMsg(null);
  };

  return (
    <div className="home_container">
      <div className="users_container">
        {users.map((user) => (
          <User
            key={user.uid}
            user={user}
            selectUser={selectUser}
            sender={sender}
            chat={chat}
          />
        ))}
      </div>
      <div className="messages_container">
        {chat ? (
          <>
            <div className="messages_user">
              <h3>{chat.name}</h3>
            </div>
            <div className="messages">
              {msgs.length
                ? msgs.map((msg, i) => (
                    <Message
                      onMessageDelete={handleMessageDelete}
                      key={i}
                      msg={msg}
                      sender={sender}
                      selected={
                        selectedMsg?.key === i &&
                        selectedMsg?.msg.name === msg.name
                      }
                      onSelected={() => {
                        setSelectedMsg({
                          key: i,
                          msg: msg,
                        });
                      }}
                    />
                  ))
                : null}
            </div>
            <MessageForm
              handleSubmit={handleSubmit}
              text={text}
              setText={setText}
              onUploadClick={handleUploadClick}
              onAudoUpload={handleAudioUpload}
            />
          </>
        ) : (
          <h3 className="no_conv">Select a user to start conversation</h3>
        )}
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={filePickerRef}
        onChange={onFileChange}
        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,.csv,.jpg,.jpeg,.png"
      />
    </div>
  );
};

export default Home;
