import React from "react";
import MicRecorder from "mic-recorder-to-mp3";
// import Attachment from "./svg/Attachment";

const recorder = new MicRecorder({
  bitRate: 128,
});

const MessageForm = ({ handleSubmit, text, setText, onUploadClick,onAudoUpload }) => {
  
 const [start,setStart]=React.useState(false);

  const onRecordStart = async () => {
   setStart(true);
    await recorder.start();
     
  };

  const onRecordStop = async () => {
   
    const result=await recorder.stop();
    const [buffer, blob]=await result.getMp3();
    const file = new File(buffer, "me-at-thevoice.wav", {
      type: blob.type,
      lastModified: Date.now(),
    });

    onAudoUpload(file);
    setStart(false);



     
  };

  return (
    <form className="message_form" onSubmit={handleSubmit}>
     
     
      <div>
        <input
          type="text"
          placeholder="Enter message"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div>
        {!start?<button className="btn" onClick={onRecordStart} type="button">
          Voice
        </button>:
        <button className="btn" onClick={onRecordStop} type="button">
          Stop
        </button>}
        <button className="btn" onClick={onUploadClick} type="button">
          Upload
        </button>
        <button className="btn">Send</button>
      </div>
    </form>
  );
};

export default MessageForm;
