import React, { useRef, useEffect } from "react";
import Moment from "react-moment";

const Message = ({ msg, sender, selected, onSelected, onMessageDelete }) => {
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [msg]);


  const playAudio=()=>{
    let audio = new Audio(msg.text);
    audio.play();
  }

  const getMessage = () => {
    
    switch (msg.type) {
      case "text":
        return <span>{msg.text}</span>;
      case "pdf":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
      case "docx":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
      case "doc":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
      case "xlsx":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
      case "ppt":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
        case "pptx":
          return (
            <a href={msg.text} target="_blank">
              {msg.name}
            </a>
          );
      case "csv":
        return (
          <a href={msg.text} target="_blank">
            {msg.name}
          </a>
        );
      case "image":
        return (
          <a href={msg.text} target="_blank">
            <img src={msg.text} style={{ width: 100 }} />
          </a>
        );
        case "audio":
          return (
           <span onClick={playAudio} onDoubleClick={()=>{
             window.open(msg.text,"_blank");
           }} style={{cursor:"pointer"}}>Voice Message</span>
          );
      default:
  

        return "";
    }
  };

  return (
    <div
      onDoubleClick={onSelected}
      className={`message_wrapper ${msg.from === sender ? "own" : ""} ${
        selected ? " selected" : ""
      }`}
      ref={scrollRef}
    >
      <p className={msg.from === sender ? "me" : "friend"}>
        {getMessage()}
        <br />
        <small>
          <Moment fromNow>{msg.createdAt.toDate()}</Moment>
        </small>
      </p>
      {selected && (
        <button className="btn" type="button" onClick={onMessageDelete}>
          Delete
        </button>
      )}
    </div>
  );
};

export default Message;
